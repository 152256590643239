@import "definitions";

/*Bestellungen Übersicht*/
$order_part_height: 330px;                                   // Höhe der einzelnen Bereiche in der Bestellübersicht
$order_label_font_size: 1.5em;                               // Schriftgröße Bereichsüberschrift z.B. Rechnungsadresse



/*Bestellugen Übersicht*/


// Order Editor

.order_view{

    .order_body{
        width: 100%; 

        .order_body_inner{ 
            display: flex;

            .order_main{
                display: flex;
                flex-direction: column;
                flex:1;

                .order_main_body {
                    display: flex;
                    flex-direction: column;;
                    flex:1;

                    tr.product_error {
                        background-color: #FF9E9E;
                    }
                  
                    .content_wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 1;

                        .stati_buttons:not(.status_dropdown), .utility_buttons, .edit_customer_data {
                            display:inline-flex;
                            flex-direction: column;

                            >*:not(:last-child) {
                                margin-bottom: $mar_small;
                            }
                        }
                    }

                    .content_separator {
                        margin: $mar;
                        border-top: $border;
                    }

                    div.missing_info_hint {
                        font-size: 1.15em;
                        margin-top: 2em;
                        font-style: italic;
                        font-weight: bold;
                    }
                }

                &.section_billing, &.section_shipping {
                    .content_separator {
                        margin: 2em 1em;
                    }
                }
            }
        }      
    }

    .order_head_label{
        font-size: $order_label_font_size;
        background: $theme_color;
        color: $theme_font_color;
        padding: $pad_small;
        margin-bottom: $mar_small;
        border-right: $border_light;
    }
    .order_main_body{
        padding: $pad;
        min-height: $order_part_height;
        border: $border;

        &.no_border_right{
            border-right: none;
        }
    }

    .property_wrapper{
        margin-bottom: $mar;
        min-height: 3em;

        select {
            width: 100%            
        }
    }

    .order_part_logistics {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .order_comment_simple {
        textarea {
            width: 100%;
            min-height: 7em;
        }
    }

    .order_part_basket{ 
        margin-top: $mar_big;
        button.button{
            margin-top: $mar;
        }

        .entry_picker {
            .add{
            @include sl-icon($fa-var-plus);
            }
            .delete{
                @include sl-icon($fa-var-trash);
            }
        }

        .add_product_part {
            display: inline-block;
            margin-bottom: $mar;
            
            &.button {
                margin: 0;
            }
        }
    }
    .form_labels{
        margin-top: 1em;        
    }

    .order_licence_codes{
        border: .5px solid lightgrey;
        padding: 1em;
        table thead, table tbody{
            tr{
                border-top: $border;
                &:last-child{
                    border-bottom: $border;
                }
                td, th{
                    border-left: $border;
                    padding: $pad_small $pad;
                    &:last-child{
                        border-right: $border;
                    }
                }

                &.error_info {
                    border-top:none;
                }
            }
        }
    } 
    
    .product_option_name {
        margin-top: $mar_small;
    }

    .product_static_name {
        margin-bottom: $mar_small;
    }
}  

.order_editor_basket > form{
    table tbody, table thead{
        tr{
            border-top: $border;
            &:last-child{
                border-bottom: $border;
            }
            td, th{
                border-left: $border;
                padding: $pad_small $pad;
                &:last-child{
                    border-right: $border;
                }
            }

            &.error_info {
                border-top:none;
            }

            td.product_name input[type="text"] {
                min-width: 20em;
                width:100%;
            }

            .price_change_launcher {
                color: #5c83e0;

                &:hover {
                    color: darken(#5c83e0, 20%);
                }
            }
        }
    }
    table tfoot{
        td, th{
            font-family: $font_family_bold;
            padding: $pad_small $pad;
            &.first{
                text-align: right;
            }
            &.middle{
                text-align: center;
            }
        }
    }
}

.orders_add_product_picker {
    display:inline-block;
    border: solid 1px $border_color;
    padding:0 .5em;
    line-height: calc(2.3em - 2px);
}

.orders_add_option_picker {
    position: relative;
    border: solid 1px $border_color;
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border:none;
        height: calc(2.3em - 2px);
        background-color: transparent;
        position: relative;
        z-index: 2;
        padding-right:2em;
    }
    i {
        position: absolute;
        right: .5em;
        top: calc(50% - .5em);
        z-index:1;
    }
}

.price_change_dialog {
    > form {
        > div {
            display: inline-block;
            vertical-align: top;
            margin-right: $mar_big;

            .price_info {
                display: inline-block;
                vertical-align: top;

                div {
                    padding: $pad_small $pad_small $pad_small 0;
                    margin-right: $mar_big;
                }
            }
        }
        > button {
            vertical-align: bottom;
        }
    }

    .price_hint {
        color: #9c9c9c;
        margin-top: $mar;
    }
}

.order_status_history {
    .history_entry {
        padding: 1em 0;
        margin: 0 1em;
        border-bottom: $border;

        &:last-child {
            border-bottom: none;                
        }
    }
}
